import React from "react";
import Layout from "layout";

import Seo from "common/seo";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";

import img1 from "images/callouts/resources2x.webp";
import img2 from "images/callouts/cout-footer-daily-skin.webp";
import img3 from "images/callouts/cout-footer-journal.webp";
import checkmarkIcon from "images/check-mark-icon.svg";

import "./thank-you.scss";

function ThankyouPage({ pageContext }) {
  return (
    <Layout pageClass="page-thankyou" breadcrumbData={pageContext.breadcrumb}>
      <Seo
        pageTitle="Sun"
        pageDescription="Register as a skin care professional"
      />
      <div className="inner-body-content no-margin-top">
        <section className="gradiant-bg">
          <div className="breadcrumb-wrap"></div>
          <div className="inner-centered-container colored-boxes">
            <Row>
              <Col sm={12}>
                <div className="thank-you-content">
                  <Row className="row">
                    <Col lg={8}>
                      <div className="content">
                        <h1>Thank you!</h1>
                        <p>You have been successfully added to the list.</p>
                        <p>
                          Keep an eye on your inbox for more information from
                          Eucerin<sup>®</sup> and Aquaphor<sup>®</sup>.
                        </p>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="media">
                        <img src={checkmarkIcon} alt="Check Mark" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img1}
                  imgAlt="Resources for Sun Care"
                  caption="Find resources to support your
                  sun care recommendations"
                  buttonText="Browse resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img2}
                  imgAlt="Explore Body Care"
                  caption="Clinically proven therapeutic
                  solutions for daily skin care"
                  buttonText="Explore Body Care"
                  buttonUrl="/body"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Discover JAAD articles"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 125 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default ThankyouPage;
